﻿$fa-font-path: '../fonts/fontawesome';
@import '../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../node_modules/@fortawesome/fontawesome-free/scss/brands';
@import '../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../node_modules/@fortawesome/fontawesome-free/scss/solid';

@import '../node_modules/@fontsource/roboto/scss/mixins';
$fontDir: '../fonts/roboto';
@include fontFaceCustom();

@import '../node_modules/@fontsource/roboto-condensed/scss/mixins';
$fontDir: '../fonts/roboto-condensed';
@include fontFaceCustom();

$flag-icons-path: "../images/flags";
@import '../node_modules/flag-icons/sass/flag-icons';

@mixin font-face($font-family, $font-path, $font-weight: normal, $font-style: normal, $font-stretch: normal) {
    @font-face {
        font-family: $font-family;
        src: url('#{$font-path}.ttf') format('truetype');
        font-weight: $font-weight;
        font-style: $font-style;
        font-stretch: $font-stretch;
    }
}

@include font-face('Linotype', '/fonts/linotype/basic-thin', thin, normal, normal);
@include font-face('Linotype', '/fonts/linotype/basic-regular', normal, normal, normal);
@include font-face('Linotype', '/fonts/linotype/basic-bold', bold, normal, normal);

@include font-face('Linotype', '/fonts/linotype/thin-condensed', thin, normal, condensed);
@include font-face('Linotype', '/fonts/linotype/regular-condensed', normal, normal, condensed);
@include font-face('Linotype', '/fonts/linotype/bold-condensed', bold, normal, condensed);
